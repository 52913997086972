<template>
  <div class="elv-valuation-record-broker-operation">
    <elv-export-button
      :params="{
        type: 'VALUATION',
        extra: { type: 'BROKER' }
      }"
      :textWeight="400"
      :noPermission="false"
    />

    <div class="elv-record-setting" @click="onCheckCostBasisMethod">
      <SvgIcon name="project-setting" width="14" height="14" fill="#838D95" />
      <div class="elv-record-setting-text">{{ t('project.costBasis.method') }}</div>
    </div>
  </div>
  <el-dialog
    v-model="showDialog"
    width="60%"
    :show-close="true"
    align-center
    destroy-on-close
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-record-dialog"
  >
    <SelectContent @on-close-dialog="onCloseDialog" />
  </el-dialog>
</template>
<script lang="ts" setup>
import { ElMessage } from 'element-plus'
import SelectContent from './SelectContent.vue'
import { useEntityStore } from '@/stores/modules/entity'
import ElvExportButton from '@/components/Base/ElvExportButton.vue'

const { t } = useI18n()
const entityStore = useEntityStore()

const showDialog = ref(false)
const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onCheckCostBasisMethod = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  showDialog.value = true
}

const onCloseDialog = () => {
  showDialog.value = false
}
</script>
<style lang="scss">
.elv-valuation-record-broker-operation {
  display: flex;
  align-items: flex-start;
  align-items: center;
  height: 15px;
  position: absolute;
  top: 35px;
  right: 32px;
}

.elv-record-setting {
  display: flex;
  align-items: flex-start;
  align-items: center;
  height: 15px;
  cursor: pointer;
  margin-left: 8px;

  svg {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }

  &:hover {
    svg {
      fill: #1343bf;
    }

    .elv-record-setting-text {
      color: #1343bf;
    }
  }

  &-text {
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
